import React, { useState } from 'react';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';

import Item01 from '@src/components/faq/item01';
import Item02 from '@src/components/faq/item02';
import Item03 from '@src/components/faq/item03';
import Item04 from '@src/components/faq/item04';
import Item05 from '@src/components/faq/item05';
import Item06 from '@src/components/faq/item06';
import Item07 from '@src/components/faq/item07';
import Item08 from '@src/components/faq/item08';
import Item09 from '@src/components/faq/item09';
import Item10 from '@src/components/faq/item10';
import Item11 from '@src/components/faq/item11';
import Item12 from '@src/components/faq/item12';
import Item13 from '@src/components/faq/item13';

const Faq = () => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  return (
    <Layout pageName={pages.faq.name} schema={pages.faq.schema}>
      <div className="with-bottom-decoration">
        <h1 className="h-center">FAQ</h1>
        <div className="subheading">
          Frequently Asked Questions
        </div>
      </div>
      <div className="c-accordion">
        <button
          type="button"
          className="c-btn c-btn--secondary"
          onClick={() => setIsAllExpanded(!isAllExpanded)}
        >
          {isAllExpanded ? 'Hide all' : 'Show all'}
        </button>
        <ul>
          <Item01 isAllExpanded={isAllExpanded} />
          <Item02 isAllExpanded={isAllExpanded} />
          <Item03 isAllExpanded={isAllExpanded} />
          <Item04 isAllExpanded={isAllExpanded} />
          <Item05 isAllExpanded={isAllExpanded} />
          <Item06 isAllExpanded={isAllExpanded} />
          <Item07 isAllExpanded={isAllExpanded} />
          <Item08 isAllExpanded={isAllExpanded} />
          <Item09 isAllExpanded={isAllExpanded} />
          <Item10 isAllExpanded={isAllExpanded} />
          <Item11 isAllExpanded={isAllExpanded} />
          <Item13 isAllExpanded={isAllExpanded} />
        </ul>
      </div>
    </Layout>
  );
};

export default Faq;
