import React from 'react';

import Item from '@src/components/faq/item';

import * as ConfigValues from '@src/helpers/configValues';

const Item09 = props => (
  <Item {...props} title="How do you handle Assembly notes on the Assembly Drawing?">
    ADCOproto is focused on quick-turn, prototype designs.
    Therefore, design reviews, special process, tooling/processing development,
    or review of any documents for notes or critical characteristics are not supplied
    through the website unless set up prior
    to the build through contacting <a href={`mailto:${ConfigValues.getAdcoEmail()}`}>{ConfigValues.getAdcoEmail()}</a>.
    All these services and more are available through ADCO Circuits directly
    but will have an impact on quick turn delivery.
  </Item>
);

export default Item09;
