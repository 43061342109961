import React from 'react';

import Item from '@src/components/faq/item';

const Item07 = props => (
  <Item
    {...props}
    title="What specific assembly services are available from ADCOproto?"
  >
    We have three modern high speed surface mount assembly lines and can manufacture
    either leaded or unleaded assemblies. Our equipment will place 0201&apos;s and
    micro BGA&apos;s and is augmented by extensive selective solder capabilities for
    through-hole devices.
  </Item>
);

export default Item07;
