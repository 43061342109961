import React from 'react';

import Item from '@src/components/faq/item';

const Item03 = props => (
  <Item {...props} title="Can the Bill of Materials have alternates?">
    Yes, our application will accept up to 3 different manufacturer part numbers
    as well as 3 distributor part numbers. These alternates can be in your excel
    spreadsheet in either columns or rows. If you don&lsquo;t have the manufacturer
    listed, the system will default to using only the distributor which will
    limit the search.
  </Item>
);

export default Item03;
