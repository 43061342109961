import React from 'react';

import Item from '@src/components/faq/item';

const Item01 = props => (
  <Item {...props} title="How does this turnkey quoting system work?">
    We have focused on the Bill of Materials as the key element in making a
    rapid delivery of circuitboard assemblies. Our application makes API calls
    to six component distributors to locate parts that are available and then
    selects the lowest price all done in real time. That said, it is looking for
    literal/exact matches to the BOM specification. The impact of this is a need
    for clarification regarding &quot;inexact matches&quot;. Our application
    will prompt you to make the necessary changes to your Bill of Materials to
    solve any issues. It will make multiple online searches as you develop
    possible supply alternatives. The end result is a Scrubbed and costed BOM
    showing any corrections needed to guarantee component availability. Then
    with a few more inputs we can finalize assembly and board costs towards the
    delivery of finished assemblies. If you register on our site we will provide
    you the Scrubbed BOM.
  </Item>
);

export default Item01;
