import React from 'react';

import Item from '@src/components/faq/item';

const Item13 = props => (
  <Item {...props} title="How can I supply my own parts?">
    <p>
      <strong>For Customer Supplied Components:</strong>
    </p>
    <ul className="c-list">
      <li>
        Surface Mount Components to be supplied in Trays or on Tape & Reel (T&R).
      </li>
      <li>
        Component strips <strong>must</strong> be in continuous length and be 4
        inches beyond the quantity required.
      </li>
      <li>
        For components with a dollar value less than $5, we require 10% extra parts. For
        components with a dollar value greater than $5, we require 5 (qty) extra parts.  
      </li>
      <li>
        For components not adhering to above, ADCO can offer T&R service for $110.00
        per part number and added lead-time.
      </li>
      <li>
        BGA&apos;s must be provided in a Matrix Tray.
      </li>
      <li>
        <strong>
          Customer to supply packing list with all shipped components that lists
          Manufacturing Part Number (MPN), Qty shipped. Labels on components must
          match packing list and include (MPN).
        </strong>
      </li>
      <li>
        If there is an inadequate supply of components, assemblies to be processed
        until components run out.
      </li>
      <li>
        <strong>Additional Charges for the following: </strong>
        Manual line placements are $10 per value and $2.10 per part. Cut tape per value is
        $3. Cut tape per placement is $.50.
      </li>
    </ul>
  </Item>
);

export default Item13;
