import React from 'react';

import Item from '@src/components/faq/item';

const Item11 = props => (
  <Item
    {...props}
    title="What files do I need to provide for the processing of my order?"
  >
    <ul className="c-list">
      <li>BOM in excel with desc., mfgr., mfgr part number, and reference designators</li>
      <li>Pick and Place file / Centroid file</li>
      <li>Gerber files & Fab dwg. (if we supply PWB&apos;s)</li>
      <li>Working Gerber files (if you supply PWB&apos;s)</li>
      <li>Assembly dwg.</li>
    </ul>
  </Item>
);

export default Item11;
