import React from 'react';

import Item from '@src/components/faq/item';

const Item08 = props => (
  <Item
    {...props}
    title="How can I be certain of getting a product that meets my quality requirements?"
  >
    All PCBA’s and applicable materials are handled and manufactured in an ANSI ESD S20 20 compliant facility. An IPC-A-610/J-STD certified trainer provides in-house training and certification to ADCO’s manufacturing workforce. Manufacturing workmanship is to IPC-A-610 Class II, with final visual inspection and standard commercial packaging practices. With over 40 years in the electronic manufacturing service business, we guarantee our workmanship.
  </Item>
);

export default Item08;
