import React from 'react';

import Item from '@src/components/faq/item';

const Item10 = props => (
  <Item {...props} title="Do you accept consignment orders?">
    All orders must meet a minimum component material value of $500.00. Customers
    can supply up to 10 line-items on the BOM free of charge.  Any additional line
    item that is marked customer supplied will cost an additional $20 per item.
  </Item>
);

export default Item10;
