import React from 'react';

import Item from '@src/components/faq/item';

// BOM example
const sampleTableDataScrubbed = [
  [
    1,
    '67840-8001',
    'Molex',
    '538-67840-8001',
    'Memory Card Connectors 2.50MM SD I/O 09P P/P',
    '',
    1,
    '$8.87',
    '$895.87',
    'ADCO',
    '',
  ],
  [
    2,
    'ADS7843E/2K5',
    'Texas Instruments',
    '1694334',
    'Resistive Touch Screen 4-Wire 16-Pin SSOP Tape and Reel',
    '',
    1,
    '$1.75',
    '$177.02',
    'ADCO',
    '',
  ],
  [
    3,
    'F930J107MAA',
    'AVX',
    '107M35AH-N-CT',
    'F93 Series 100 uF ±20 % 6.3 V Surface Mount Standard J-Lead Tantalum Capacitor',
    '',
    2,
    '$0.16',
    '$33.13',
    'ADCO',
    '',
  ],
  [
    4,
    'LM1117MPX-3.3/NOPB',
    'Texas Instruments',
    '14527989',
    'LDO Regulator Pos 3.3V 0.8A 4-Pin(3+Tab) SOT-223 T/R',
    '',
    1,
    '$0.70',
    '$70.60',
    'ADCO',
    '',
  ],
  [
    5,
    'FH12A-40S-0.5SH(55)',
    'HIROSE ELECTRIC',
    'FH12A-40S-0.5SH-55-CT',
    'FH12 Series 40 Position 0.5 mm Pitch SMT Right Angle Flip Lock FFC/FPC',
    '',
    1,
    '$1.56',
    '$157.56',
    'ADCO',
    '',
  ],
  [
    6,
    'C0805C104M5RACTU',
    'KEMET',
    'C0805C104M5RACTU.',
    'Multilayer Ceramic Capacitors MLCC - SMD/SMT 50volts 0.1uF X7R 20%',
    '',
    4,
    '$0.02',
    '$9.50',
    'ADCO',
    '',
  ],
  [
    7,
    'RP73PF2A2K0BTDF',
    'TE Connectivity / Holsworthy',
    '279-RP73PF2A2K0BTDF',
    'Thin Film Resistors - SMD RP 2A 0.25W 2K0 0 .1% 25PPM 1K RL',
    '',
    3,
    '$0.25',
    '$75.75',
    'ADCO',
    '',
  ],
  [
    8,
    'RMCF0805ZG0R00',
    'SEI Stackpole Electronics',
    '1325009',
    'Res Thick Film 0805 0 Ohm Pad SMD Automotive T/R',
    '',
    2,
    '$0.01',
    '$2.86',
    'ADCO',
    '',
  ],
];

const Item02 = props => (
  <Item
    {...props}
    title="What does the scrubbed and costed Bill of Materials look like?"
  >
    <table>
      <thead>
        <tr>
          <th />
          <th>A</th>
          <th>B</th>
          <th>C</th>
          <th>D</th>
          <th>E</th>
          <th>F</th>
          <th>G</th>
          <th>H</th>
          <th>I</th>
          <th>J</th>
          <th>K</th>
        </tr>
      </thead>
      <tbody>
        <tr className="highlighted">
          <td>1</td>
          <td>Seq #</td>
          <td>Mfr. Part #</td>
          <td>Manufacturer</td>
          <td>Distributor Part #</td>
          <td>Description</td>
          <td>Reference Designators</td>
          <td>Qty Per Board</td>
          <td>Unit price</td>
          <td>Extended Sale</td>
          <td>Supply</td>
          <td>Alternate to above</td>
        </tr>
        {sampleTableDataScrubbed.map(item => (
          <tr key={item[0]}>
            <td>{item[0] + 1}</td>
            <td>{item[0]}</td>
            <td>{item[1]}</td>
            <td>{item[2]}</td>
            <td>{item[3]}</td>
            <td>{item[4]}</td>
            <td>{item[5]}</td>
            <td>{item[6]}</td>
            <td>{item[7]}</td>
            <td>{item[8]}</td>
            <td>{item[9]}</td>
            <td>{item[10]}</td>
          </tr>
        ))}
        <tr>
          <td>10</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td>11</td>
          <td>assembly quantity</td>
          <td>100</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>Misc Tape & Reel Total</td>
          <td>$21.00</td>
          <td />
          <td />
        </tr>
        <tr>
          <td>12</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>Extended Sale Total</td>
          <td>$1,422.29</td>
          <td />
          <td />
        </tr>
        <tr>
          <td>13</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>Total Cost</td>
          <td>$1,443.29</td>
          <td />
          <td />
        </tr>
        <tr>
          <td>14</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>Total Selling Price</td>
          <td>$1,948.44</td>
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  </Item>
);

export default Item02;
