import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { customEvent } from '@src/helpers/gtag';

const Item = ({ title, children, isAllExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => setIsExpanded(isAllExpanded), [isAllExpanded]);

  return (
    <li
      className={isExpanded ? 'is-opened' : null}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <header
        onClick={() => {
          customEvent({
            action: isExpanded ? 'Collapse' : 'Expand',
            category: 'FAQ Item',
            label: title,
          });
          setIsExpanded(!isExpanded);
        }}
        itemProp="name"
      >
        {title}
      </header>
      <section
        className="c-accordion-section"
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <div itemProp="text">
          {children}
        </div>
      </section>
    </li>
  );
};

Item.propTypes = {
  isAllExpanded: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Item;
