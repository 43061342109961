import React from 'react';

import Item from '@src/components/faq/item';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';
import * as ConfigValues from '@src/helpers/configValues';

const sampleTableData = [
  [1, '678408001', 'Molex', 1, 'J1', 'TE, 11 pin (9+2), Push-In Push-Out'],
  [
    2,
    'ADS7843E/2K5',
    'Texas Instruments',
    1,
    'U2',
    'Touch Screen Controller, TI, SSOP',
  ],
  [3, 'F930J107MAA', 'AVX', 2, 'C1,C4', 'Tantalum, 100uF, 20%, 6.3V, 2 Ohm, 1206'],
  [
    4,
    'LM1117MPX-3.3/NOPB',
    'Texas Instruments',
    1,
    'J3',
    '800mA, Low-Dropout Reg., 4-pin SOT-223',
  ],
  [
    5,
    'FH12A-40S-0.5SH(55)',
    'HIROSE',
    1,
    'U1',
    '40pin FPC/FFC, 0.5mm pitch, Flip Lock, Top Connector',
  ],
  [6, 'C0805C104M5RACTU', 'Kemet', 4, 'C2,C3,C5,C6', 'Cap, 0.1, 20%, 50WVDC, X7R, 0805'],
  [7, 'RP73PF2A2K0BTDF', 'TE', 3, 'R1,R2,R3', 'Res, 2k, 0.1%, 0.25W, 0805'],
  [8, 'RMCF0805ZG0R00', 'Stackpole', 2, 'R4', 'jumper, 0.125W, 0805'],
];

const Item04 = props => (
  <Item
    {...props}
    title="What is the best way to structure the Bill of Materials?"
  >
    <p>
      A Bill of Materials (BOM) should use column headers, may not contain extra lines,
      and should include all required columns. An example is below.
      Please be very careful with SMT connector Manufacturer Part Numbers,
      as they need to indicate Tape and Reel packaging (with pick up points). Please
      contact <a href={`mailto:${ConfigValues.getAdcoEmail()}`}>{ConfigValues.getAdcoEmail()}</a> with any questions.
    </p>
    <br />
    <table>
      <thead>
        <tr>
          <th />
          <th>A</th>
          <th>B</th>
          <th>C</th>
          <th>D</th>
          <th>E</th>
          <th>F</th>
        </tr>
      </thead>
      <tbody>
        <tr className="highlighted">
          <td>1</td>
          <td>Item</td>
          <td>Manufacturer PN</td>
          <td>Manufacturer</td>
          <td>Qty per</td>
          <td>Reference designators</td>
          <td>Description</td>
        </tr>
        {sampleTableData.map(item => (
          <tr key={item[0]}>
            <td>{item[0] + 1}</td>
            <td>{item[0]}</td>
            <td>{item[1]}</td>
            <td>{item[2]}</td>
            <td>{item[3]}</td>
            <td>{item[4]}</td>
            <td>{item[5]}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br />
    <p>
      If you don&quot;t have any BOM ready yet, use our{' '}
      <a
        href="/adco_bom_template.csv"
        onClick={customEvent(events.faqDownloadTemplate('csv'))}
      >
        CSV
      </a>{' '}
      or{' '}
      <a
        href="/adco_bom_template.xlsx"
        onClick={customEvent(events.faqDownloadTemplate('xlsx'))}
      >
        XLSX
      </a>{' '}
      template or play with our{' '}
      <a
        href="/demo_bom.xlsx"
        onClick={customEvent(events.faqDownloadBOM())}
      >
        sample BOM
      </a>
      .
    </p>
  </Item>
);

export default Item04;
