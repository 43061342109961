import React from 'react';

import Item from '@src/components/faq/item';

const Item05 = props => (
  <Item {...props} title="Is there a minimum order size?">
    Yes, the component portion of the quotation must equal at least $500.00. If
    your BOM cost is under $500.00, the site will notify you, and you can decide to
    proceed knowing you will be charged $500.00 for material or restart the project
    with a higher quantity of assemblies.
  </Item>
);

export default Item05;
