import React from 'react';

import Item from '@src/components/faq/item';

const Item06 = props => (
  <Item {...props} title="How quickly can you deliver my prototypes?">
    ADCOproto offers a 5,10, or 15 day assembly time from receipt of all parts.
    This is the time of the actual assembly in working days. We also need 7 working
    days to gather the parts before production starts.
  </Item>
);

export default Item06;
